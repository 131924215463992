import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';

interface User {
  node: {
    login: string;
    avatarUrl: string;
    url: string;
  };
}

interface Props {
  users: User[];
  onUserClick: (login: string) => void;
}

const UserList: React.FC<Props> = ({ users, onUserClick }) => {
  const [currentPage, setCurrentPage] = useState(0); // Tracks the current page
  const itemsPerPage = 6; // Number of users to show per page
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const handleUserClick = (login: string) => {
    setSelectedUser(login);
    onUserClick(login);
  };

  // Calculate the index range for the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the users for the current page
  const visibleUsers = users.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (endIndex < users.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className='text-left'>Users:</h1>
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 0}
              >
                <span aria-hidden="true">&lt; Prev</span>
              </Pagination.Prev>
              <Pagination.Next
                onClick={handleNextPage}
                disabled={endIndex >= users.length} // Disable if on the last page
              >
              <span aria-hidden="true">Next &gt;</span>
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
      <div className="row">
        {visibleUsers.map(({ node }) => ( 
            
          <div className="col-sm-2 col-md-2 col-lg-2 mb-3" key={node.login}>
            <Card
              style={{
                width: '100%',
                cursor: 'pointer',
                border: '1px solid lightgray',
                boxShadow: selectedUser === node.login ? '0px 4px 15px rgba(0, 0, 225, 0.5)' : 'none',
                  transition: 'box-shadow 0.3s ease-in-out'
              }}
              onClick={() => handleUserClick(node.login)}
            >
              <Card.Img variant="top" src={node.avatarUrl} alt={node.login} style={{ width: '100%', height: '50%' }} />
              <Card.Body>
                <Card.Text className="text-center">
                  {node.login}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div >
    </>
  );
};

export default UserList;
