import React, { useEffect, useState }  from "react";
import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserSearch from "./components/UserSearch";
import RepoIssues from "./components/RepoIssues";
import GetTokenModal from "./components/GetTokenModal";

function App() {

  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Check if a personal token exists in session storage
    const token = sessionStorage.getItem('personalToken');
    // console.log("Token in App: ", token);
    if (!token) {
      setModalVisible(true); 
    }
  }, []); 

  const handleModalClose = () => {
    setModalVisible(false); 
  };

  return (
    <Router>
      {isModalVisible && <GetTokenModal isShow={isModalVisible} onHide={handleModalClose} />}

      <Routes>
        <Route path="/" element={<UserSearch />} />
        <Route path="/repo-issue" element={<RepoIssues />} />
      </Routes>
    </Router>
  );
}

export default App;
