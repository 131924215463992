import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

// Define props interface
interface GetTokenModalProps {
  isShow: boolean;
  onHide: () => void; // Define the type for onHide
}

const GetTokenModal: React.FC<GetTokenModalProps> = ({ isShow, onHide }) => {
    const navigate = useNavigate();
  const [personalToken, setPersonalToken] = useState(""); // State for storing the token
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationError, setMutationError] = useState<Error | null>(null); // Type for mutationError

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (personalToken) {
      setMutationLoading(true);
      setMutationError(null);

      try {
        await submitToken(personalToken); // Call the modified function
        alert('Token submitted successfully'); // Handle success
        onHide(); // Close the modal after successful submission
        navigate("/"); // Redirect to home page
      } catch (error) {
        setMutationError(error as Error); // Set error state
      } finally {
        setMutationLoading(false); // Reset loading state
      }
    }
  };

  const submitToken = (token: string) => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (token === "fail") {
          reject(new Error("Token submission failed")); // Simulate failure
        } else {
          sessionStorage.setItem('personalToken', token); // Store token in session storage
          resolve(); // Simulate success
        }
      }, 1000);
    });
  };

  return (
    <Modal show={isShow} onHide={onHide} backdrop="static" contentLabel="Get Personal Token">
      <Modal.Header>
        <Modal.Title>Get Personal Token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mutationLoading && <div className="alert alert-info">Setting token...</div>}
        {mutationError && <div className="alert alert-danger">Error: {mutationError.message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="getToken">Add Token:</label>
            <input
              id="getToken"
              type="text"
              className="form-control"
              value={personalToken}
              onChange={(e) => setPersonalToken(e.target.value)}
              placeholder="Enter your personal access token ..."
              required
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-success btn-md">Submit</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default GetTokenModal;
